<template>
<div class=" mx-auto">
        <Modal v-show="showSuccessModal" :heightContainer="'auto'" :widthContainer="'25%'">
          <template v-slot:header>
            <h2 class="title">Recordatorio</h2>
          </template>
          <template v-slot:body>
            <p class="description">Puede realizar el pago mas tarde de forma manual.</p>
          </template>
          <template v-slot:footer>
            <div style="display:flex;justify-content:center;">
              <input type="button" class="next_button" value="Continuar" :disabled="!timeIntervalFinished"
              v-bind:style="{opacity: timeIntervalFinished ? activeColor : activeColor2 }"  v-on:click="redirect()" />
            </div>
          </template>
        </Modal>

        <div>
            <Loading :isLoading= "this.isLoading" />
            <div>
                <br>
                <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                    <div class="row">
                        <center>
                            <p class="title">
                                Pago de parte social para inscripción a FONCABSA</p>
                        </center>
                    </div>
                </div>
                <br>
                <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                    <div class="row">
                        <center>
                            <!--<p class="subTitle">¿Desea realizar el pago de su inscripción?</p>-->
                            <br>
                            <p class="description">El pago puede realizarse con tarjeta desde la plataforma, por medio de transferencia bancaria, o bien, realizar el pago en banco.</p>
                        </center>
                    </div>
                </div>
                <br>
                <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                    <div class="row">
                        <div class="col col-sm-4 mt-8">
                        </div>
                        <div class="col col-sm-4 mt-8">
                            <center>
                                <input class="button_popups" v-on:click="generateSessionPayment()" type="button"  value="Pagar con cargo a tarjeta">
                                <br>
                                <br>
                                <!--<input class="next_button" v-on:click="showSuccessModal=true, startCounting()" type="button" value="Pagar inscripción más tarde">-->
                                <input class="button_popups_no_background" v-on:click="redirect()" style="color:black; border-color:black" type="button" value="Información para pago en banco">
                            </center>
                        </div>
                        <div class="col col-sm-3 mt-8">
                        </div>
                    </div>
                </div>
                <br>
                <br>
                <br>
            </div>
        </div>
    <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
    <!--<link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> -->
</div>
</template>

<script>
import('@/assets/css/stylesheet.css')
import Modal from '@/components/Modal/Modal.vue'
import MenuLateral from "@/components/Cuenta/MenuLateral.vue";
import Loading from "@/components/Loading/VueLoading.vue";
import Swal from "sweetalert2";
import moment from "moment";
import { saveRequest,getAdmissionRequest } from "@/api/user";
import Vue from "vue";
import {getTokenDecoden} from "@/helpers/tokenauth";


export default {
  data() {
    return {
      activeColor: "1",
      activeColor2: "0.4",
      errors: [],
      list_colonys: [],
      isLoading: false,
      multiApi: process.env.VUE_APP_MULTIAPI,
      ccapi: process.env.VUE_APP_CCAPI,
      urlWeb: process.env.VUE_APP_URLWEB,
      user_id:null,
      admission_request_id:null,
      enable_payment_buttons:false,
      is_payment_stripe:false,
      is_payment_manually:false,
      //enrollment_fee_method:"",
      json:null,
      showSuccessModal:false,
      timeIntervalFinished: false,
      startTime: null,
      idInterval: 0
    };
  },
  name: "PagoSuscripcion",
  components: {
    MenuLateral,
    Loading,
    Modal
  },
  props: {
    msg: String,
  },
  async mounted() {
  },
  async beforeMount() {
    await this.loadInfo();
    await this.searchInfo();
  },
  watch : {

  },
  methods: {
    async loadInfo() {
      var auth = getTokenDecoden();
      this.user_id = auth["id"];
      this.json = this.getJsonFromLocalStorageBy(this.user_id);
    },
    setPersonalInfoToLocalStorage(user_id, jsonPersonalInfo) {
      sessionStorage.setItem(user_id, JSON.stringify(jsonPersonalInfo));
    },
    getJsonFromLocalStorageBy(user_id) {
      return JSON.parse(window.sessionStorage.getItem(user_id));
    },
    async searchInfo() {
      //this.enrollment_fee_method = this.json.admission_request_params.admission_information.enrollment_fee_method != "" ? this.json.admission_request_params.admission_information.enrollment_fee_method : "";
      //if(this.enrollment_fee_method == ""){
      //  window.location.href ="/"
      //}
    },
    async generateSessionPayment(){
      var swal = require('sweetalert2')
      this.isLoading = true;
      await this.axios.post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/enroll_payment_fee/create_payment_session_url`, {
          token_auth:  sessionStorage.getItem("login"),
          success_url: this.urlWeb+"/cuenta/pago-parte-social/pago-exitoso",
          cancel_url: this.urlWeb+"/cuenta/principal"
      }, { 'Content-Type': 'application/json' })
      .then(response => {
          this.isLoading = false;
          // window.location.href= response.data.response.session_url
          this.$router.push({path:response.data.response.session_url});
      }).catch(error => {
          this.isLoading = false;
          swal.fire({
          title: "Aviso",
          text: "Ocurrió un error al generar sesion de pago",
          icon: "info",
          confirmButtonColor: '#FEB72B',
          });
      });
    },
    redirect(){
      //window.location.href ="/cuenta/pago-parte-social/referencia-de-pago";
      // window.location.href ="/cuenta/pago-parte-social/pago-banco-transferencia";
      this.$router.push({path:"/cuenta/pago-parte-social/pago-banco-transferencia"});

    },
    startCounting() {
      this.startTime = Date.now();
      this.idInterval = setInterval(this.refresh, 500);
    },
    refresh() {
      let seconds = this.getSeconds();
      if (seconds >= 7) {
        this.timeIntervalFinished = true;
        clearInterval(this.idInterval);
        return;
      }
    },
    getSeconds() {
      return (Date.now() - this.startTime) / 1000;
    },
    redirectToHome() {
      // window.location.href ="/cuenta/principal";
      this.$router.push({path:"/cuenta/principal"});
    },
  },
};
</script>

<style>

@media (min-width: 766px) {
    .collapse.dont-collapse-sm {
        display: block;
        height: auto !important;
        visibility: visible;
    }
    .border{
        border-right: 1px dashed #333;
        border-top: 1px dashed #ffffff;
        border-left: 1px dashed #ffffff;
        border-bottom: 1px dashed #ffffff;
    }
}

@media (max-width: 766px) {
    .border{
        margin-left: 9px;
        margin-right: -15px;
        border-right: 1px dashed #ffffff;
        border-top: 1px dashed #ffffff;
        border-left: 1px dashed #ffffff;
        border-bottom: 1px dashed #ffffff;
    }
}


#button_popups {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #feb72b !important;
  color: #fff !important;
  font-weight:bold !important;
  width: 280px !important;
  height: 40px !important;
  padding-top: 0% !important;
  font-size:16px !important;
}

@media (max-width: 500px) {
  #button_popups {
    height: 50px !important;
  }
}


.button_popups {
  border-radius: 5px;
  background-color: #FEB72B;
  color: #fff;
  font-family: Roboto;
  font-weight:400;
  width: 100%;
  height: 40px;
  padding:10px;
  font-size:16px;
}

.button_popups_no_background {
  border-radius: 5px;
  border: 1px solid #222222;
  background-color: #fff;
  color: #222222;
  font-family: Roboto;
  font-weight:400;
  width: 100%;
  height: 40px;
  padding:10px;
  font-size:16px;
}


</style>
